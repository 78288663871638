import { useDispatch } from 'react-redux';
import { userApi, getValidResponseBody } from '../api';
import { setProfile } from '../state/profile/profile';
import { useAuth0 } from './auth0';
import { useState, useEffect } from 'react';

const addUserTokenHeaderToOptions = (options, token) => {
  const authHeader = token ? { Authorization: `Bearer ${token}` } : {};
  const prevHeaders = options?.headers ? options.headers : {};
  return {
    ...options,
    headers: { ...prevHeaders, ...authHeader },
  };
};

export const useUserProfile = (userJWTDirect) => {
  const dispatch = useDispatch();
  const { tokenClaims } = useAuth0();
  const [queue, setQueue] = useState([]);

  useEffect(() => {
    const token = userJWTDirect || tokenClaims?.__raw;
    if (token && queue?.length) {
      queue.forEach((fn) => fn(token));
      setQueue([]);
    }
  }, [queue, tokenClaims?.__raw, userJWTDirect]);

  //fns and postEffectFns are endpoint invokers which will have auth token injected in a runtime once available
  //functions to be called with no callback
  const fns = ['getUserData', 'businesSignup', 'signup'];
  //functions to be called with a calback
  const postEffectFns = [
    'addViewedProduct',
    'removeViewedProducts',
    'deleteWishlistProduct',
    'addWishlistProduct',
    'deleteBlogBookmark',
    'addBlogBookmark',
    'setUserData',
    'deleteAccount',
    'cancelAccountDeletion',
  ];
  //Functions mentined in two arrays above are returned from useUserProfile hook

  const reloadReduxProfile = async (token) => {
    const updatedProfileResp = await userApi.getUserData({
      headers: { Authorization: `Bearer ${token}` },
    });

    if (updatedProfileResp?.ok) {
      const updatedProfile = getValidResponseBody(updatedProfileResp);
      dispatch(setProfile(updatedProfile));
      localStorage.setItem('reduxProfile', JSON.stringify(updatedProfile));
    }
  };

  const invokeRequestCurried = (endpoint, callback) => (options) => {
    const action = (token) => async () => {
      const optionsWithAuthToken = addUserTokenHeaderToOptions(options, token);
      const response = await userApi[endpoint](optionsWithAuthToken);
      if (typeof callback === 'function') callback(token);
      return response;
    };
    const token = userJWTDirect || tokenClaims?.__raw;
    if (!token) {
      return new Promise((resolve) => {
        setQueue((queue) => [...queue, (token) => resolve(action(token)())]);
      });
    } else {
      return action(token)();
    }
  };

  const proxyTarget = {};
  const proxyHandler = {
    get(target, propfn, receiver) {
      if (fns.includes(propfn)) return invokeRequestCurried(propfn);
      else if (postEffectFns.includes(propfn)) return invokeRequestCurried(propfn, reloadReduxProfile);
    },
  };
  const userApiProxy = new Proxy(proxyTarget, proxyHandler);
  return userApiProxy;
};
