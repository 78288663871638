import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from './auth0';
import { getValidResponseBody, searchApi } from '../api';
import { setWishlistItems, setWishlistProducts } from '../state/wishlist/wishlist';
import { migrateAnonUserWishlist } from './wishlistMigration';

//todo: transfer anonymously liked to user profile in useEffect
// const combinedWishlistObject = anonymousLikedProducts?.length
//           ? {
//               likedProducts: [...userProfile.likedProducts, ...anonymousLikedProducts],
//             }
//           : {};
// save updated profile in user api
// await setUserData({
//   body: JSON.stringify(reduxProfile),
//   headers: { Authorization: `Bearer ${tokenClaims?.__raw}` },
// }).catch((reason) => {
//   console.warn("Can't save local rvp to profile:", reason);
// });

/**
 * useWishlistUpdater loads relevant wishlist on initial app load and stores it in a redux
 */

const useWishlistUpdater = () => {
  const profile = useSelector(({ profile }) => profile);
  const profilePresent = !!profile;
  const profileWishlistItems = profile?.wishlistItems;
  const anonymousLikedProducts = useSelector(({ anonymousLikedProducts }) => anonymousLikedProducts);
  //wishlist can have items of two types: string and object
  const wishlistDetailed = useSelector(({ wishlist }) => wishlist?.wishlistItems);

  const { tokenClaims } = useAuth0();
  const authToken = tokenClaims?.__raw;
  const dispatch = useDispatch();

  const updateWishlist = useCallback(
    async (incomingWishlist = []) => {
      //todo: two dispatches causes double app rerendering. Try to avoid it
      dispatch(setWishlistItems(incomingWishlist));

      if (!incomingWishlist?.length) {
        dispatch(setWishlistProducts([]));
        return;
      }
      const productsHeaders = !!authToken ? { Authorization: `Bearer ${authToken}` } : {};

      const productItem = {
        method: 'post',
        headers: productsHeaders,
        body: JSON.stringify({ ids: incomingWishlist.map(({ productId }) => productId) }),
      };

      const productsResponse = await searchApi.getWishlistProfile(productItem);
      if (!productsResponse?.ok) dispatch(setWishlistProducts([]));
      const products = getValidResponseBody(productsResponse);
      if (!Array.isArray(products)) return;

      //create an iterable argument for Map constructor with product id as a key and null as a default value
      const formattedWishlistArray = incomingWishlist.map(({ productId }) => [
        productId,
        { id: productId, unavailable: true },
      ]);
      const wishlistMap = new Map(formattedWishlistArray);
      //fill in Map with existing products data from API
      products.forEach((product) => {
        wishlistMap.set(product.id, product);
      });
      const wishlistProducts = wishlistMap.values().toArray();

      dispatch(setWishlistProducts(wishlistProducts));
    },
    [authToken, dispatch]
  );

  useEffect(() => {
    migrateAnonUserWishlist(dispatch);
  });

  useEffect(() => {
    const lastStoredWishlistProduct = wishlistDetailed?.slice(-1)?.[0]?.productId;

    const selectedWishlist = profilePresent ? profileWishlistItems : anonymousLikedProducts;
    //profile wishlist and anonymousLikedProducts items are always objects
    const lastIncomingWishlistProduct = selectedWishlist?.slice(-1)?.[0]?.productId;
    //do nothing if there are no updates to user wishlist from user api
    if (
      lastStoredWishlistProduct === lastIncomingWishlistProduct &&
      wishlistDetailed?.length === selectedWishlist?.length
    ) {
      //at this point wishlistDetailed should be up to date and it's safe to store it to LS
      if (!profilePresent) localStorage.setItem('wishlistItems', JSON.stringify(wishlistDetailed));
      return;
    }

    updateWishlist(selectedWishlist);
  }, [anonymousLikedProducts, profilePresent, updateWishlist, wishlistDetailed, profileWishlistItems]);
};

export default useWishlistUpdater;

//todo: gets invoked several times yet
