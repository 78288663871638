const WEBSHOP_AFFILIATE_ID = 255559;

const addTrackingData = (param, clkEncoded, isType1) => {
  const clk = decodeURIComponent(clkEncoded);
  if (param.match('{trackingData}')) return param.replace('{trackingData}', clk);
  if (isType1) return clk;
  //type2 tt param should contain 4 values separated by _ including WEBSHOP_AFFILIATE_ID
  const res = param
    .split('_')
    .slice(0, 3)
    .concat([clk])
    .join('_');
  if (res.includes(WEBSHOP_AFFILIATE_ID) && res.split('_').length === 4) return res;
  return param;
};

const sendBeacon = (url, clk) => {
  const searchParams = url.split('?')[1];
  const queryStringParams = new URLSearchParams(searchParams);
  const ttLink = url.includes('tradetracker.net');

  const searchableParam = ttLink ? 'c' : 'tt';
  const paramWithId = queryStringParams.get(searchableParam);

  const id = paramWithId ? (ttLink ? paramWithId.substring(2) : paramWithId.split('_')[0].substring(3)) : '';
  //todo: does it work now? should all platforms point to this domain?
  const lead = `https://jouw.webshop.nl/?s2s=1&event=lead&cid=27671&pid=53075&tid=${id}&data=${clk}`;
  if (paramWithId) {
    navigator.sendBeacon(lead);
  }

  return true;
};

const createUrlWithClk = (url, clk) => {
  const [urlDomain, searchParams] = url.split('?');
  const queryStringParams = new URLSearchParams(searchParams);
  const type1 = url.includes('tradetracker.net');

  const clkParamName = type1 ? 'r' : 'tt';
  const clkParamValue = queryStringParams.get(clkParamName);

  if (!type1 && clkParamValue === null) return url;

  const paramWithClk = addTrackingData(clkParamValue || '', clk, type1);
  queryStringParams.set(clkParamName, paramWithClk);

  const newUrl = `${urlDomain}?${queryStringParams.toString()}`;

  return newUrl;
};

export default function clickoutScript(url, clk, href) {
  if (href) return createUrlWithClk(url, clk);
  else return sendBeacon(url, clk);
}
